import React, { useEffect, useState } from "react";
import Topbar from "../../layout/Topbar";
import { Footer } from "../../components/Footer/Footer";
import RadRun from "../../assets/images/radrun1.png";
import Routing from "../../assets/icon/routing.png";
import EventCard from "../../components/cards/EventCard";
import RadRun1 from "../../assets/images/radrun1.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchEventById,
  markEventComplete,
} from "../../redux1/Slices/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../services/constants";
import moment from "moment";
import GoogleMap from "../Googlemap";
import { Skeleton } from "primereact/skeleton";
import ContentCard from "../../components/cards/ContentCard";
import { Card } from "primereact/card";
import upcomingEventImage from "../../assets/icon/upcoming-event.png";
import PrimaryButton, {
  PrimaryButtonOutlined,
} from "../../components/buttons/button";
import CustomTable from "../../components/table/table";
import { TableEventDetails } from "./LatestEvents/TableEventDetails";
import { ReactComponent as Empty } from "../../assets/svg/empty.svg";
import QRScanner from "../../components/QrScanner/QrScanner";
import {
  createRun,
  getRadRunByEventId,
  markRunAsComplete,
} from "../../redux1/Slices/radRunSlice";
import ConfirmDailog from "../../components/overlay/Dialog";
import { imagePath } from "../../utils/commonFunctions";
import { useDebounce } from "primereact/hooks";

const EventInfo = () => {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState();
  const radRunData = useSelector((state) => state.radRun.radRunByEventData);
  const [address, setAddress] = useState();
  const { id } = useParams();
  const { EventColumns } = TableEventDetails();
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);
  const [filterData, setFilterData] = useState({
    searchKeys: JSON.stringify(["child.firstName", "child.lastName"]),
    searchString: "",
  });

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchEventById({ id, setEventData }));
  }, [id]);

  useEffect(() => {
    dispatch(getRadRunByEventId({ id, filterData }));
  }, [id, filterData]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const handleScan = (childId) => {
    const data = {
      childId,
      eventId: id,
    };
    dispatch(createRun({ data, setIsScannerOpen }));
  };

  useEffect(() => {
    if (eventData?.address) setAddress(eventData?.address);
  }, [eventData]);

  const markRadRunAsComplete = () => {
    setDialog({
      visible: true,
      title:
        "Are you sure you want to mark this rad run as complete? This action cannot be undone.",
      type: "COMPLETE",
      cancel: true,
      saveFunction: () =>
        dispatch(markEventComplete({ id, setEventData, setDialog })),
    });
  };

  const upcomingEventHeader = (
    <div className="flex justify-content-center mt-4">
      <img alt="Card" src={upcomingEventImage} style={{ width: "30%" }} />
    </div>
  );
  return (
    <>
      <Topbar></Topbar>
      <ConfirmDailog dialog={dialog} setDialog={setDialog} />
      <QRScanner
        visible={isScannerOpen}
        onScan={(e) => {
          handleScan(e._id);
        }}
        onHideScanner={() => setIsScannerOpen(false)}
      />
      <div className="content-box px-2 pb-3">
        <h1 className="kagitingan lg:text-5xl text-xl">{eventData?.name}</h1>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div>
              <img
                className="border-round-top-lg"
                src={eventData?.image ? imagePath(eventData?.image) : RadRun}
                alt=""
                style={{
                  width: "100%",
                  height: "25em",
                }}
              />
              <div
                className="flex flex-column p-3 line-height-4 border-round-bottom-lg bg-gray-100"
                style={{ marginTop: "-5px" }}
              >
                <span className="flex align-content-center text-sm">
                  <i className="pi pi-calendar mr-2 text-xl  text-blue"></i>
                  {moment(eventData?.date).format("ddd DD/MM/YYYY")}
                </span>
                <span className="flex align-items-center text-sm  inline-block ">
                  <i className="pi pi-clock mr-2 text-xl  text-blue"></i>
                  At {moment(eventData?.time).format("hh:mm a")}
                </span>
                <span className="flex align-items-center line-height-3 my-2">
                  <i className="pi pi-map-marker mr-2 text-xl  text-blue"></i>
                  <span className="text-sm ">{address?.name}</span>
                </span>
                <span className="flex align-items-center inline-block ">
                  <img src={Routing} alt="" />
                  <span className="text-sm ml-2">
                    {eventData?.loopDistance}
                  </span>
                </span>
                <p className="subheading pointer-events-none">
                  <div
                    style={{ textAlign: "justify" }}
                    contentEditable="true"
                    dangerouslySetInnerHTML={{ __html: eventData?.description }}
                  ></div>
                </p>
                {/* <span className="flex align-items-center subheading text-sm  inline-block mb-2">
                  <i className="pi pi-check mr-2 text-sm pink font-bold"></i>
                  {"At 07:00am"}
                </span>
                <span className="flex align-items-center subheading text-sm  inline-block mb-2">
                  <i className="pi pi-check mr-2 text-sm pink font-bold"></i>
                  {"At 07:00am"}
                </span>
                <span className="flex align-items-center subheading text-sm  inline-block mb-2">
                  <i className="pi pi-check mr-2 text-sm pink font-bold"></i>
                  {"At 07:00am"}
                </span>
                <span className="flex align-items-center subheading text-sm  inline-block mb-2">
                  <i className="pi pi-check mr-2 text-sm pink font-bold"></i>
                  {"At 07:00am"}
                </span> */}
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 md:w-5 mx-auto mb-3 md:mb-0">
            <GoogleMap address={address} />
          </div>
        </div>
        <div className="md:flex justify-content-between align-items-center">
          <h1 className="kagitingan lg:text-5xl text-xl px-1">Stats</h1>
          {moment(new Date(eventData?.date)).isSame(
            moment(new Date()),
            "day"
          ) && !eventData.isCompleted ? (
            <div className="flex gap-2">
              {radRunData?.length ? (
                <PrimaryButtonOutlined
                  className={"w-auto skyblue"}
                  extraClass={"w-auto"}
                  label={"Mark as complete"}
                  onClick={markRadRunAsComplete}
                ></PrimaryButtonOutlined>
              ) : null}
              <PrimaryButton
                className={"w-auto"}
                extraClass={"text-right w-auto"}
                label={"Start enrollment"}
                onClick={() => setIsScannerOpen(true)}
              ></PrimaryButton>
            </div>
          ) : null}
        </div>
        {moment(new Date(eventData?.date)).isAfter(moment(new Date()), "day") &&
        !eventData.isCompleted ? (
          <div className="card flex justify-content-center">
            <Card className="w-full" title="" header={upcomingEventHeader}>
              <p className="m-0 text-center">
                The event has not taken place yet. The statistics will appear
                after the event has occurred.
              </p>
            </Card>
          </div>
        ) : (
          <ContentCard
            heading={eventData?.name}
            inputSearchExtraClass="w-full"
            headingSize={"text-center md:text-left text-2xl"}
            inputSearch={inputSearch}
            setSearch={setSearchValue}
            filterData={filterData}
            setFilterData={setFilterData}
          >
            <CustomTable
              columns={EventColumns}
              emptyMessage={emptyMessage}
              data={radRunData}
            />
          </ContentCard>
        )}
      </div>
    </>
  );
};

export default EventInfo;
