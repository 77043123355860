import React, { useState } from "react";
import CustomSignInCard from "../../components/cards/CustomSignInCard";
import Topbar from "../../layout/Topbar";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { ResetPasswordAction } from "../../redux1/Slices/registerSlice";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState({
    email: state?.email,
    password: "",
    confirmPassowrd: "",
  });
  console.log("state", state);

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleResetPassword = () => {
    if (showFormErrors(data, setData)) {
      console.log("data", data);
      dispatch(ResetPasswordAction({ data, navigate }));
    }
  };
  return (
    <>
      <Topbar />
      <div className="background-image">
        <div className="flex justify-content-center align-items-center w-full h-100-top">
          <CustomSignInCard
            eventorganizer
            resetPassword
            data={data}
            setData={setData}
            buttonLabel={"CONTINUE"}
            heading={"RESET PASSWORD"}
            SubHeading={
              "Your new password must be unique from those previously used."
            }
            checkbox
            handleChange={handleChange}
            submit={handleResetPassword}
          ></CustomSignInCard>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
