import React, { useEffect, useState } from "react";
import PrimaryButton from "../buttons/button";
import { Dialog } from "primereact/dialog";
import CustomUploadFile from "../UploadFile/CustomUploadFile";
import {
  CustomDropDown,
  CustomFloatInput,
  CustomInputphone,
} from "../input/input";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editProfile } from "../../redux1/Slices/loginSlice";

const EditProfileDetails = ({
  heading,
  save,
  SubHeading,
  identifier,
  visible,
  setVisible,
  image,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state?.user?.data);
  const TeacherRole = userProfile?.role === "TEACHER";
  const EventOrgnizerRole = userProfile?.role === "ORGANIZER";
  const [isoCode, setIsoCode] = useState("us");

  const options = [
    { name: "Individual", value: "Individual" },
    { name: "School", value: "School" },
    { name: "Clubs/Groups", value: "Clubs/Groups" },
  ];
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    image: "",
    phone: "",
    schoolName: "",
    postCode: "",
    password: "",
    confirmPassword: "",
    organizerType: "",
    role: userProfile?.role?.toUpperCase(),
  });

  const handleChange = ({ name, value }) => {
    console.log("name", name);
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleEditProfile = () => {
    console.log("edit", showFormErrors(data, setData));
    const ignore =
      userProfile?.role === "TEACHER"
        ? ["organizerType"]
        : userProfile?.role === "PARENT"
        ? ["organizerType", "organizationName"]
        : ["postCode", "phone"];
    if (showFormErrors(data, setData, ignore)) {
      dispatch(editProfile({ data, setVisible }));
    }
  };
  useEffect(() => {
    setData(userProfile);
  }, [userProfile]);
  return (
    <Dialog
      visible={visible}
      modal
      style={{ width: "50rem" }}
      className="border-round-lg"
      onHide={() => setVisible(false)}
      content={({ hide }) => (
        <div className="flex justify-content-center border-round-lg inter">
          <div className="signup md:col-6 lg:col-11 p-4 col-11">
            <div className="text-center pb-2 pb-4">
              <span className="kagitingan font-bold lg:text-4xl text-lg ">
                {heading}
              </span>
              <div className="text-sm subheading mt-2">{SubHeading}</div>
              {identifier ? (
                <div className="center align-items-center text-base mt-2">
                  <h4 className="mr-4">RAD RUN Identifier </h4>
                  <span className="text-sm pink">{identifier}</span>
                </div>
              ) : (
                ""
              )}
              {image ? <CustomUploadFile data={data} setData={setData} /> : ""}
            </div>
            <div className="grid">
              {EventOrgnizerRole ? (
                <CustomDropDown
                  options={options}
                  optionLabel="name"
                  col={12}
                  label={"Who are you ?"}
                  name="organizerType"
                  value={data?.organizerType}
                  data={data}
                  onChange={handleChange}
                ></CustomDropDown>
              ) : (
                ""
              )}
              <CustomFloatInput
                name="firstName"
                data={data}
                value={data?.firstName}
                onChange={handleChange}
                col={6}
                label={"First Name"}
              ></CustomFloatInput>
              <CustomFloatInput
                name="lastName"
                data={data}
                value={data?.lastName}
                onChange={handleChange}
                col={6}
                label={"Last Name"}
              ></CustomFloatInput>
              <CustomFloatInput
                name="email"
                value={data?.email}
                data={data}
                onChange={handleChange}
                col={
                  data.organizerType === "Individual" && EventOrgnizerRole
                    ? 12
                    : 6
                }
                label={"Email"}
              ></CustomFloatInput>
              {EventOrgnizerRole ? (
                ""
              ) : (
                <CustomInputphone
                  col={6}
                  label={"Mobile Number"}
                  name="Phone Number"
                  value={data?.phone}
                  isoCode={isoCode}
                  data={data}
                  onChange={handleChange}
                ></CustomInputphone>
              )}
              {data.organizerType === "School" ? (
                <CustomFloatInput
                  name="organizationName"
                  value={data?.organizationName}
                  data={data}
                  onChange={handleChange}
                  col={6}
                  label={"School Name"}
                ></CustomFloatInput>
              ) : data.organizerType === "Clubs/Groups" ? (
                <CustomFloatInput
                  name="organizationName"
                  value={data?.organizationName}
                  data={data}
                  onChange={handleChange}
                  col={6}
                  label={"Club Name"}
                ></CustomFloatInput>
              ) : (
                ""
              )}
              {!EventOrgnizerRole ? (
                TeacherRole ? (
                  <>
                    <CustomFloatInput
                      name="organizationName"
                      value={data?.organizationName}
                      data={data}
                      onChange={handleChange}
                      col={6}
                      label={"School Name"}
                    ></CustomFloatInput>
                    <CustomFloatInput
                      name="postCode"
                      value={data?.postCode}
                      data={data}
                      onChange={handleChange}
                      col={6}
                      label={"Postcode"}
                    ></CustomFloatInput>{" "}
                  </>
                ) : (
                  <CustomFloatInput
                    name="postCode"
                    value={data?.postCode}
                    data={data}
                    onChange={handleChange}
                    col={12}
                    label={"Postcode"}
                  ></CustomFloatInput>
                )
              ) : (
                ""
              )}
            </div>
            <div className="center align-items-center gap-3 ">
              <PrimaryButton
                col={3}
                extraClass={""}
                label={"Save"}
                onClick={handleEditProfile}
              ></PrimaryButton>
              <PrimaryButton
                col={3}
                secondary
                extraClass={""}
                label="cancel"
                onClick={() => setVisible(false)}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      )}
    ></Dialog>
  );
};

export default EditProfileDetails;
