import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";
import { fetchClubsAndSChools } from "./parentSlice";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
  isAddSchoolModalVisible: false
};

export const registrationContext = createAsyncThunk(
  "auth/sign-up",
  async ({ data, navigate }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const payload = {
        ...data,
        postCode: data.postCode <= 0 ? null : data.postCode
      };
      const res = await api("post", Constants.END_POINT.REGISTER, payload);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        // authenticate(res.data.token, () => navigate("/dashboard"));
        navigate("/otp", {
          state: {
            email: data?.email,
            otpType: 1,
          },
        });
        // localStorage.setItem("User", JSON.stringify(res.data));
        return res.data;
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
        return Promise.reject(new Error("Request was not successful"));
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async ({ data, setShowOtp, setUser }, { dispatch }) => {
    let email = "";
    try {
      dispatch(showLoader());
      const payload = {
        ...data,
      };
      const res = await api(
        "post",
        Constants.END_POINT.FORGOT_PASSWORD,
        payload
      );
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        setShowOtp(true);
        email = res.data;
        return email;
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
        return Promise.reject(new Error("Request was not successful"));
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/verify-otp",
  async (
    { payload, setSuccess = () => null, navigate = () => null },
    { dispatch }
  ) => {
    console.log("first");
    let userRole = "";
    dispatch(showLoader());
    try {
      const res = await api("post", Constants.END_POINT.REQUEST_OTP, payload);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );

        userRole = res.data.role;
        setSuccess(true);
        navigate("/reset-password", {
          state: {
            email: payload?.email,
          },
        });
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
      }
      return userRole;
    } catch (error) {
      console.log("error", error);
      // Handle error if needed
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const ResetPasswordAction = createAsyncThunk(
  "auth/reset-password",
  async ({ data, navigate }, { dispatch }) => {
    dispatch(showLoader());
    try {
      const res = await api("post", Constants.END_POINT.RESET_PASSWORD, data);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        navigate("/success");
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
      }
      return;
    } catch (error) {
      // Handle error if needed
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const AddClubAction = createAsyncThunk(
  "auth/add-club",
  async ({ data, next }, { dispatch }) => {
    dispatch(showLoader());
    try {
      const res = await api("post", Constants.END_POINT.ADD_CLUB, data);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        dispatch(hideAddSchoolModal());
        dispatch(fetchClubsAndSChools({}));
        next();
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
      }
      return;
    } catch (error) {
      // Handle error if needed
    } finally {
      dispatch(hideLoader());
    }
  }
);

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    showAddSchoolModal: (state) => {
      state.isAddSchoolModalVisible = true;
    },
    hideAddSchoolModal: (state) => {
      state.isAddSchoolModalVisible = false;
    },
    toggleAddSchoolModal: (state) => {
      state.isAddSchoolModalVisible = !state.isAddSchoolModalVisible;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(registrationContext.pending, (state) => {
      state.isLoading = true;
      state.error = null; // Reset error on pending
    });
    builder.addCase(registrationContext.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(registrationContext.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { showAddSchoolModal, hideAddSchoolModal, toggleAddSchoolModal } = registrationSlice.actions;

export default registrationSlice.reducer;
