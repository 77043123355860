import React, { useState } from "react";
import {
  CustomCheckbox,
  CustomDropDown,
  CustomFloatInput,
  CustomFloatInputNumber,
  CustomFloatPassword,
  CustomInputphone,
  CustomMultiSelect,
} from "../input/input";
import PrimaryButton from "../buttons/button";
import { useNavigate } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { registrationContext } from "../../redux1/Slices/registerSlice";
import { useDispatch } from "react-redux";
import { languageOptions, yesNoOptions } from "../../utils/constants";

const CustomRegisterCard = ({ heading, subHeading, checkbox }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isoCode, setIsoCode] = useState("us");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    postCode: "",
    speakOtherLanguage: null,
    otherLanguages: [],
    password: "",
    confirmPassword: "",
    role: "PARENT",
  });

  const handleChange = ({ name, value }) => {
    if (name === "speakOtherLanguage" && !value) data.otherLanguages = [];
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleLogin = () => {
    const ignore = [
      "postCode",
      "phone",
      "speakOtherLanguage",
      "otherLanguages",
    ];
    if (showFormErrors(data, setData, ignore)) {
      dispatch(registrationContext({ data, navigate }));
    }
  };

  return (
    <div className="signup md:col-6 lg:col-6 col-10 lg:p-6 p-5 lg:my-3  my-5 ">
      <div className="text-center lg:text-5xl text-xl font-bold kagitingan">
        {heading}
      </div>
      <p className="md:px-8 pb-2 md:pb-5">{subHeading}</p>
      <div className="grid">
        <CustomFloatInput
          name="firstName"
          data={data}
          onChange={handleChange}
          col={6}
          label={"First Name"}
        ></CustomFloatInput>
        <CustomFloatInput
          name="lastName"
          data={data}
          onChange={handleChange}
          col={6}
          label={"Last Name"}
        ></CustomFloatInput>
        <CustomFloatInput
          name="email"
          data={data}
          onChange={handleChange}
          label={"Email"}
        ></CustomFloatInput>
        <CustomInputphone
          col={6}
          label={"Phone Number"}
          name="phone"
          isoCode={isoCode}
          data={data}
          onChange={handleChange}
        ></CustomInputphone>
        <CustomFloatInputNumber
          name="postCode"
          maxLength={4}
          data={data}
          value={data?.postCode}
          onChange={handleChange}
          col={12}
          label={
            <span>
              Postcode{" "}
              <span className="text-xs text-600">
                (*Please enter 4 numbers only)
              </span>
            </span>
          }
        ></CustomFloatInputNumber>
        <CustomDropDown
          options={yesNoOptions}
          optionLabel="name"
          col={12}
          showFilter={false}
          label={"Do you speak a language other than English at home?"}
          name="speakOtherLanguage"
          value={data.speakOtherLanguage}
          data={data}
          onChange={handleChange}
        ></CustomDropDown>
        {data.speakOtherLanguage ? (
          <CustomMultiSelect
            inputClass={"bg-white border-400"}
            options={languageOptions}
            optionLabel=""
            col={12}
            showFilter={false}
            label={"What language other than English do you speak at home?"}
            name="otherLanguages"
            value={data.otherLanguages}
            data={data}
            onChange={handleChange}
          ></CustomMultiSelect>
        ) : null}
        <CustomFloatPassword
          name="password"
          data={data}
          onChange={handleChange}
          col={6}
          label={"Password"}
          extraClassName={"mb-2"}
        ></CustomFloatPassword>
        <CustomFloatPassword
          name="confirmPassword"
          data={data}
          onChange={handleChange}
          col={6}
          extraClassName={"mb-2"}
          label={"Confirm Password"}
        ></CustomFloatPassword>
      </div>
      {checkbox ? (
        <CustomCheckbox
          extraClassName={"mb-2"}
          onChange={(e) => setChecked(e.checked)}
          checked={checked}
        ></CustomCheckbox>
      ) : (
        ""
      )}
      <div className="center align-items-center flex-column ">
        <PrimaryButton
          col={3}
          disable={!checked}
          label={"Register"}
          onClick={handleLogin}
        ></PrimaryButton>
        <h4 className="font-normal mb-0" onClick={() => navigate("/login")}>
          Already have an account? &nbsp;
          <span className="pink cursor-pointer font-bold underline">
            Sign In
          </span>
        </h4>
      </div>
    </div>
  );
};

export default CustomRegisterCard;
