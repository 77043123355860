import React, { useEffect, useState } from "react";
import Topbar from "../../layout/Topbar";
import { RequestEvent } from "../../components/cards/StartRadRun/RequestEvent";
import { Footer } from "../../components/Footer/Footer";

export const StartRadRun = () => {
  return (
    <>
      <Topbar></Topbar>
      <div className="content-box px-3">
        <h1 className="kagitingan lg:text-5xl text-lg mb-0">Start a RAD RUN</h1>
        <p className="line-height-3 text-gray-500">
          "Are you ready to embark on an awesome adventure? Join us in creating
          your very own RAD RUN! Whether you're a parent, a teacher, or just
          someone who loves fun, fitness and community, you can lead one of our
          amazing RADMIN teams. <br />
          <span className="my-3 inline-block">
            What's a RADMIN, you ask? Well, they're the super cool folks who
            make RAD RUN events happen! They're like the superheroes of health
            and happiness! And guess what? You don't need any fancy coaching
            skills to join in the fun. As long as you believe in our RAD RUN
            VALUES and pass a few safety checks (which we'll help you with),
            you're good to go!
          </span>
          So, are you ready to be a part of something amazing? Let's make
          memories, get our kids active, and spread joy together!
        </p>
        <h1 className="kagitingan lg:text-5xl text-lg mb-0">
          Your ideal RAD RUN location
        </h1>
        <p className="line-height-3 text-gray-500 mb-7">
          When picking the perfect spot for your RAD RUN, we need to make sure
          it's super safe and totally awesome for everyone. We want a place
          where everyone feels welcome and included, and where we can all make
          new friends and have a blast together! <br />
          <span className="my-3 inline-block">
            Your RAD RUN course will be super duper simple to set up (200m-600m
            in length); maybe a loop around a cool track or a footy field. It
            could even wind through a small park or around a little lake, adding
            to the excitement and adventure!
          </span>
          Lastly, let’s try to find a spot that's near all sorts of awesome
          stuff like playgrounds, yummy cafes, and even toilets (because you
          know, nature calls!). We want families to have a great time playing
          and hanging out together, before, during, and after the big event.
          <span className="my-3 inline-block">
            So let's pick the perfect place and get ready for the most RAD RUN
            ever!"
          </span>
        </p>
      </div>
      <RequestEvent />
      <div className="p-8 signup"></div>
      <Footer />
    </>
  );
};
