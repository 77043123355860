import React from "react";

const EventCard = ({ title, date, time, address, distance, src, onClick }) => {
  return (
    <>
      <div
        className="col-3 md:col-4 adjustWidth cursor-pointer"
        onClick={onClick}
      >
        <div className="mb-2 ">
          <img
            src={src}
            alt=""
            className="border-round-xl"
            style={{ width: "100%", height: "400px" }}
          />
        </div>
        <div className="flex flex-column">
          <span className="text-2xl kagitingan inline-block mb-2">{title}</span>
          <span className="flex align-content-center text-sm subheading">
            <i className="pi pi-calendar mr-2 text-xl  text-blue"></i>
            {date}
          </span>
          <span className="flex align-items-center text-sm subheading inline-block my-2">
            <i className="pi pi-clock mr-2 text-xl text-blue"></i>
            {time}
          </span>
          <span className="flex align-items-center line-height-3">
            <i className="pi pi-map-marker mr-2 text-xl text-blue"></i>
            <span className="text-sm subheading">
              {address} <br /> ({Math.round(distance) + " KMs"})
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default EventCard;
