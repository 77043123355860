import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

export const TableEventDetails = () => {
  const navigate = useNavigate();
  const EventColumns = [
    {
      field: "position",
      header: "Position",
      body: (row) => {
        return (
          <>
            <h1 className="text-base  text-red-500">{row.position}</h1>
          </>
        );
      },
    },
    {
      field: "child.firstName",
      header: "Full Name",
      body: (row) => {
        return (
          <>
            <h1 className="text-base font-normal pink cursor-pointer" onClick={() => navigate("/profile/" + row?.child?._id)}>{row.child.firstName + " " + row.child.lastName}</h1>
          </>
        );
      },
      sortable: true,
    },
    {
      field: "child.gender",
      header: "Gender",
      body: (row) => {
        return (
          <>
            <div
              className="text-center border-round-2xl "
              style={{ backgroundColor: row.child.gender === "Male" ? "#EBFBFF" : "#FFE3F6" }}
            >
              <h1 className={`text-base font-normal p-1 ${row.child.gender === 'Male' ? 'skyblue' : 'pink'}`}>{row.child.gender}</h1>
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      field: "child.dob", header: "Age", sortable: true,
      body: (row) => {
        return (
          <>
            <h1 className="text-base font-normal p-1">{moment().diff(new Date(row.child.dob), 'years')}</h1>
          </>
        )
      }
    },
    { field: "child.class", header: "Class", sortable: true },
    { field: "child.house", header: "House", sortable: true },
    {
      field: "distanceAchieved", header: "Distance achieved", sortable: true,
      body: (row) => {
        return (
          <>
            <h1 className="text-base font-normal p-1">{
              row.distanceAchieved ? (row.distanceAchieved / 1000).toFixed(2) + " KM" : null
            }</h1>
          </>
        )
      }
    },
    {
      field: "radRunsCompleted",
      header: "No. of RAD RUNs Completed",
      sortable: true,
    },
  ];

  const StatsColumns = [
    {
      field: "eventName",
      header: "Event Name",
      body: (row) => {
        return (
          <>
            <h1
              className="text-base font-normal cursor-pointer pink"
              onClick={() => navigate("/event-details/" + row?.event._id)}
            >
              {row?.event?.name}
            </h1>
          </>
        );
      },
    },
    {
      field: "event.date",
      header: "Date",
      sortable: true,
      body: (row) => {
        return (
          <>
            <h1
              className="text-base font-normal"
            >
              {moment(row?.event?.date).format("DD-MM-YYYY")}
            </h1>
          </>
        );
      },
    },
    {
      field: "firstFinisher",
      header: "First Finisher",
      body: (row) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => navigate("/profile/" + row?._id)}
            >
              <h1 className="text-base font-normal p-1 pink">{row?.firstFinisher}</h1>
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      field: "totalDistanceCovered",
      header: "All-time distance achieved",
      sortable: true,
      body: (row) => {
        return (
          <>
            <h1 className="text-base font-normal p-1">{(row?.totalDistanceCovered / 1000).toFixed(2)} KM</h1>
          </>
        );
      },
    },
    {
      field: "radRunsCompleted",
      header: "No. of RAD RUNs Completed",
      sortable: true,
    },
  ];

  const ProfileColumns = [
    {
      field: "eventName",
      header: "Event Name",
      body: (row) => {
        return (
          <>
            <h1 className="text-base font-normal pink">
              {row?.event?.name}
            </h1>
          </>
        );
      },
    },
    {
      field: "event.date",
      header: "Date",
      sortable: true,
      body: (row) => {
        return (
          <>
            <h1
              className="text-base font-normal"
            >
              {moment(row?.event?.date).format("DD-MM-YYYY")}
            </h1>
          </>
        );
      },
    },
    {
      field: "position",
      header: "Position",
      sortable: true,
    },
    {
      field: "totalDistanceCovered",
      header: "Distance Achieved",
      sortable: true,
      body: (row) => {
        return (
          <>
            <h1 className="text-base font-normal p-1">{(row?.totalDistanceCovered / 1000).toFixed(2)} KM</h1>
          </>
        );
      },
    },
    {
      field: "radRunsCompleted",
      header: "No. of RAD RUNs Completed",
      sortable: true,
    },
  ];
  return {
    EventColumns,
    StatsColumns,
    ProfileColumns,
  };
};
