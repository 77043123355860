import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table/table";
import { ReactComponent as Empty } from "../../assets/svg/empty.svg";
import { ReactComponent as SearchEmpty } from "../../assets/svg/emptysearch.svg";

import { ReactComponent as EmptyEvents } from "../../assets/svg/EmptyEvent.svg";
import ContentCard from "../../components/cards/ContentCard";
import PrimaryButton from "../../components/buttons/button";
import Topbar from "../../layout/Topbar";
import { TableColumns } from "./TableColumns";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllChildren,
  fetchUniqueId,
  updateChildProfile,
} from "../../redux1/Slices/parentSlice";
import { GetPrivateEvent } from "../../redux1/Slices/eventSlice";
import html2canvas from "html2canvas";
import { AddApplicants } from "./AddApplicants";
import QRCard from "../../components/qrCard/qrCard";
import { useDebounce } from "primereact/hooks";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [qrImg, setQRImg] = useState(false);
  const [qrVisible, setQRVisible] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);
  const { ParentColumns } = TableColumns();
  const userProfile = useSelector((state) => state.user.data);
  const ChildrenData = useSelector((state) => state.children.allChild);
  const TeacherRole = userProfile?.role === "TEACHER";
  const EventOrgnizerRole = userProfile?.role === "ORGANIZER";
  const searchKeys = JSON.stringify(
    userProfile?.role === "ORGANIZER" ? ["name"] : ["firstName", "lastName"]
  );

  const [filterData, setFilterData] = useState({
    searchKeys,
    searchString: "",
  });

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    // email: "",
    // phone: "",
    school: "",
    club: "",
    // postCode: "",
    gender: "",
    // organizerType: "",
    class: "",
    house: "",
    team: "",
    dob: "",
    // country: "",
    // state: "",
    // city: "",
    // comments: "",
    // school: "",
    childIdentifiedAsIslander: null,
    hasDisabilityOrLongTermHealthCondition: null,
    // disabilityOrDimensionOfRestrictedAccess: null,
    // otherSpecificationValue: null,
    howManyDaysChildWasPhysicallyActive: null,
    role: "CHILD",
  });

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        {userProfile?.role === "ORGANIZER" ? (
          <>
            <EmptyEvents />
            <h2 className="inter text-sm">No event to listed here.</h2>{" "}
          </>
        ) : (
          <>
            {inputSearch ? (
              <>
                <SearchEmpty />
                <h2 className="inter">No Result Found</h2>
              </>
            ) : (
              <>
                <Empty />
                <h2 className="inter">
                  No children to listed here. Please add children.
                </h2>
                <PrimaryButton
                  label={
                    TeacherRole
                      ? "Add Student"
                      : EventOrgnizerRole
                      ? "Request RAD RUN"
                      : "Add Child"
                  }
                  onClick={() => {
                    setVisible(true);
                    getUniqueId();
                  }}
                ></PrimaryButton>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const deleteEmployee = (id) => {
    setDialog({
      visible: true,
      title:
        "Are you sure you want to delete this entry? This action cannot be undone.",
      type: "DELETE",
      cancel: true,
      saveFunction: () =>
        dispatch(
          updateChildProfile({ payload: { id, isDeleted: true }, setDialog })
        ),
    });
  };
  const qrCodeFun = (col) => {
    setQRVisible(true);
    setQRImg(col);
  };

  const DownloadQRCode = async () => {
    const element = document.getElementById("print"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    link.download = "downloaded-image.jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Edit CHILDREN
  const handleEditRow = (col) => {
    setData({
      id: col._id,
      firstName: col?.firstName,
      lastName: col?.lastName,
      gender: col?.gender,
      dob: col?.dob,
      class: col?.class,
      house: col?.house,
      school: col?.school._id,
      club: col?.club._id,
      team: col?.team,
      childIdentifiedAsIslander: col?.childIdentifiedAsIslander,
      hasDisabilityOrLongTermHealthCondition:
        col?.hasDisabilityOrLongTermHealthCondition,
      howManyDaysChildWasPhysicallyActive:
        col?.howManyDaysChildWasPhysicallyActive,
      radRunIdentifier: col?.radRunIdentifier,
    });
    setVisible(true);
  };

  // GET UNIQUE ID
  const getUniqueId = () => {
    dispatch(fetchUniqueId({}));
  };
  // FETCH ALL CHILDREN
  useEffect(() => {
    dispatch(fetchAllChildren({ filterData }));
  }, [userProfile, filterData]);
  // useEffect(() => {
  //   dispatch(fetchAllChildren({  }));
  // }, [debouncedValue]);
  return (
    <>
      <Topbar />
      <QRCard
        qrVisible={qrVisible}
        setQRVisible={setQRVisible}
        qrImg={qrImg}
        DownloadQRCode={DownloadQRCode}
      />
      <div className="content-box  lg:py-4 p-3  ">
        <div className="shadow-2 border-round-xl">
          <ContentCard
            inputSearch={inputSearch}
            setSearch={setSearchValue}
            ChildrenData={ChildrenData}
            setDialog={setDialog}
            setVisible={setVisible}
            filterData={filterData}
            setFilterData={setFilterData}
            dialog={dialog}
            placeholder={
              TeacherRole
                ? "by Student Name"
                : EventOrgnizerRole
                ? "by Events"
                : "by Child Name"
            }
            button
            onClick={() => {
              setVisible(true);
              getUniqueId();
            }}
            label={
              TeacherRole
                ? "Add Student"
                : EventOrgnizerRole
                ? "Request RAD RUN"
                : "Add Child"
            }
            heading={
              TeacherRole
                ? "STUDENT LIST"
                : EventOrgnizerRole
                ? "All Events"
                : "MY RAD RUNNERS"
            }
          >
            <div className="px-3" style={{ minHeight: "30rem" }}>
              <CustomTable
                columns={ParentColumns}
                emptyMessage={emptyMessage}
                data={ChildrenData}
                edit={handleEditRow}
                trash={deleteEmployee}
                qrCode={qrCodeFun}
              />
            </div>
          </ContentCard>
        </div>
        <AddApplicants
          data={data}
          setData={setData}
          visible={visible}
          filterData={filterData}
          setVisible={setVisible}
          setSearch={setSearchValue}
        />
      </div>
    </>
  );
};

export default Dashboard;
