import React, { useEffect, useState } from "react";
import {
  CustomCheckbox,
  CustomDropDown,
  CustomFloatInput,
  CustomFloatInputNumber,
  CustomFloatPassword,
  CustomInputphone,
  CustomMultiSelect,
} from "../input/input";
import PrimaryButton from "../buttons/button";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  AddClubEntityAction,
  getEntity,
  updateEntityAction,
} from "../../redux1/Slices/parentSlice";

const AddEntityCard = ({ clubId, entityId, setEntityId, setVisible, type }) => {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();

  const typeOptions =
    type === "SCHOOL"
      ? [
          {
            name: "Class",
            value: "CLASS",
          },
          {
            name: "House",
            value: "HOUSE",
          },
        ]
      : [
          {
            name: "Team",
            value: "TEAM",
          },
        ];

  const [data, setData] = useState({
    _id: "",
    name: "",
    entityType: "",
    club: clubId,
  });

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleAddEntity = () => {
    const ignore = ["_id"];
    if (showFormErrors(data, setData, ignore)) {
      if (data._id) {
        dispatch(updateEntityAction({ data, clubId, setVisible }));
      } else {
        dispatch(AddClubEntityAction({ data, setVisible }));
      }
    }
  };

  useEffect(() => {
    if (entityId) {
      dispatch(getEntity({ entityId, setData }));
    }
  }, []);

  return (
    <div className="signup p-5 overflow-auto">
      <div className="mb-5">
        <div className="flex justify-content-between lg:text-5xl text-xl font-bold kagitingan">
          {"Create Entity"}
          <i
            className="pi pi-times cursor-pointer"
            onClick={() => {
              setEntityId(null);
              setVisible(false);
            }}
          ></i>
        </div>
      </div>
      <div className="grid">
        <CustomDropDown
          showFilter={false}
          options={typeOptions}
          optionLabel="name"
          data={data}
          col={12}
          label={"Type"}
          name="entityType"
          value={data?.entityType}
          onChange={handleChange}
        ></CustomDropDown>

        <CustomFloatInput
          name="name"
          value={data?.name}
          data={data}
          onChange={handleChange}
          col={12}
          label={"Name"}
        ></CustomFloatInput>
      </div>
      <div className="center align-items-center flex-column">
        <PrimaryButton
          col={4}
          label={data?._id ? "Update" : "Add"}
          onClick={handleAddEntity}
        ></PrimaryButton>
      </div>
    </div>
  );
};

export default AddEntityCard;
