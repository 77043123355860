import React, { useState } from "react";
import Topbar from "../layout/Topbar";
import PrimaryButton from "../components/buttons/button";
import { useSelector } from "react-redux";
import EditProfileDetails from "../components/cards/EditProfileDetails";
import { imagePath } from "../utils/commonFunctions";

export const EditProfile = () => {
  const [visible, setVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const userProfile = useSelector((state) => state?.user?.data);
  const TeacherRole = userProfile?.role === "TEACHER";
  const EventOrgnizerRole = userProfile?.role === "ORGANIZER";
  const ParentRole = userProfile?.role === "PARENT";

  return (
    <>
      <Topbar />
      <div className="content-box lg:py-4 p-3">
        <div className="shadow-2 border-round-xl p-4">
          <h4 className="lg:text-4xl text-lg kagitingan mt-0 mb-4">
            Personal Details
          </h4>
          <div className="flex flex-wrap align-items-center justify-content-between shadow-2 border-round-xl gap-2 p-4">
            {userProfile?.image ? (
              <img
                className="p-2 border-circle"
                src={imagePath(userProfile?.image)}
                alt="Profile"
                width={80}
                height={80}
              />
            ) : (
              <span className="inline-flex border-1 border-circle p-2">
                <i className="pi pi-user text-5xl"></i>
              </span>
            )}
            <div className=" flex flex-wrap align-items-center justify-content-between lg:gap-5 gap-3">
              {EventOrgnizerRole ? (
                <div className="">
                  <div className="font-semibold mb-2">who are you</div>
                  <div>{userProfile?.organizerType}</div>
                </div>
              ) : (
                ""
              )}
              <div className="">
                <div className="font-semibold mb-2">First Name</div>
                <div>{userProfile?.firstName}</div>
              </div>
              <div className="">
                <div className="font-semibold mb-2">Last Name</div>
                <div>{userProfile?.lastName}</div>
              </div>
              <div className="">
                <div className="font-semibold mb-2">Email</div>
                <div>{userProfile?.email}</div>
              </div>
              {ParentRole ? (
                <div className="">
                  <div className="font-semibold mb-2">Postcode</div>
                  <div>{userProfile?.postCode}</div>
                </div>
              ) : (
                ""
              )}
              {EventOrgnizerRole ? (
                <div className="">
                  <div className="font-semibold mb-2">
                    {userProfile?.organizerType === "Clubs/Groups"
                      ? "Club Name"
                      : "School Name"}
                  </div>
                  <div>{userProfile?.organizationName}</div>
                </div>
              ) : (
                <div className="">
                  <div className="font-semibold mb-2">Phone Number</div>
                  <div>{userProfile?.phone}</div>
                </div>
              )}

              {TeacherRole ? (
                <>
                  <div className="">
                    <div className="font-semibold mb-2">Postcode</div>
                    <div>{userProfile?.postCode}</div>
                  </div>
                  <div className="">
                    <div className="font-semibold mb-2">
                      {userProfile?.organizationName}
                    </div>
                    <div>{userProfile?.organizationName}</div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <PrimaryButton
              onClick={() => setVisible(true)}
              label={"Edit"}
              col={1}
            ></PrimaryButton>
          </div>
          <h4 className="lg:text-4xl text-lg kagitingan mb-4">
            Change Password
          </h4>
          <div className="flex flex-wrap lg:gap-3   align-items-center justify-content-between shadow-2 border-round-xl p-4">
            <div className="lg:col-6 col-12">
              <div className="font-semibold mb-2">Password</div>
              <span className="text-gray-500">**********</span>
            </div>
            <PrimaryButton
              onClick={() => setPasswordVisible(true)}
              label={"Edit"}
              col={1}
            ></PrimaryButton>
          </div>
        </div>
      </div>
      <EditProfileDetails
        visible={visible}
        setVisible={setVisible}
        heading={"Edit Personal details"}
        image
      ></EditProfileDetails>
    </>
  );
};
