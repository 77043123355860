import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { hideDataLoader, showDataLoader } from "./loaderSlice";
import { showToast } from "./toastSlice";
import { getRadRunByEventId } from "./radRunSlice";

const initialState = {
  data: null,
  // loading: false,
  // error: null,
};

export const addPrivateEvent = createAsyncThunk(
  "api/events",
  async ({ data, setVisible, setData }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api(
        "post",
        Constants.END_POINT.ADD_PRIVATE_EVENTS,
        data
      );
      if (res.success && res.data) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(GetPrivateEvent({}));
        setVisible(false);
        setData({});
        // dispatch(eventSlice.actions.setPrivteEvent(res.data));s
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const GetPrivateEvent = createAsyncThunk(
  "api/events",
  async ({ filterData }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const searchParams = new URLSearchParams();

      Object.keys(filterData).forEach(key => {
        if (filterData[key] !== undefined && filterData[key] !== null) {
          searchParams.append(key, filterData[key]);
        }
      });
      const res = await api("get", Constants.END_POINT.PRIVATEEVENTS + '?' + searchParams);
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(eventSlice.actions.setPrivteEvent(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const addPublicEvent = createAsyncThunk(
  "api/events",
  async ({ data, setData }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("post", Constants.END_POINT.PUBLICEVENT, data);
      if (res.success && res.data) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        setData({});
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const fetchEventById = createAsyncThunk(
  "api/events/all/",
  async ({ id, setEventData }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("get", Constants.END_POINT.FETCHALLEVENTBYID + id);
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        setEventData(res.data);
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const getNearestEvents = createAsyncThunk(
  "api/events/nearest/",
  async ({ payload, setEvents }, { dispatch, getState }) => {
    try {

      const res = await api("post", Constants.END_POINT.NEAREST_EVENTS, { ...payload });
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        setEvents(res.data);
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const getAllEvents = createAsyncThunk(
  "api/events/nearest/",
  async ({ filterData, setEvents }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const searchParams = new URLSearchParams();

      Object.keys(filterData).forEach(key => {
        if (filterData[key] || filterData[key] !== undefined && filterData[key] !== null) {
          searchParams.append(key, filterData[key]);
        }
      });

      const res = await api("get", Constants.END_POINT.ALL_EVENTS + '?' + searchParams);
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        setEvents(res.data);
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const markEventComplete = createAsyncThunk(
  "api/events/mark-as-complete",
  async ({ id, setEventData, setDialog }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("get", Constants.END_POINT.MARK_EVENT_AS_COMPLETE + id);
      if (res.success) {
        setDialog(false);
        dispatch(fetchEventById({ id, setEventData }));
        dispatch(getRadRunByEventId({ id }));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setPrivteEvent: (state, action) => {
      state.privateEventData = action.payload;
    },
    setAllEventById: (state, action) => {
      state.AllEventDataById = action.payload;
    },
  },
});

export const { setPrivteEvent, setAllEventById } = eventSlice.actions;

export default eventSlice.reducer;
