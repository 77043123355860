import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";

import {
  hideDataLoader,
  hideLoader,
  showDataLoader,
  showLoader,
} from "./loaderSlice";
import { showToast } from "./toastSlice";
import { hideAddSchoolModal } from "./registerSlice";

const initialState = {
  allChild: null,
  club: null,
  clubsAndSchools: null,
  clubs: null,
  schools: null,
  clubEntities: null,
  addClubModalData: {},
  singleChild: null,
  uniqueId: null,
};

export const fetchUniqueId = createAsyncThunk(
  "user/getUniqueId",
  async ({ data = null }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("get", Constants.END_POINT.UNIQUE_ID);
      if (res.success && res.data) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setUniqueId(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const addChild = createAsyncThunk(
  "user/create-child",
  async ({ payload, setVisible, setData }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("post", Constants.END_POINT.ADD_CHILD, payload);
      if (res.success && res.data) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(fetchAllChildren({}));
        setData({});
        setVisible(false);
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const fetchChildProfile = createAsyncThunk(
  "user/login",
  async ({ data = null }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("get", Constants.END_POINT.GET_CHILD_BY_ID);
      if (res.success && res.data) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setChildDetail(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const fetchAllChildren = createAsyncThunk(
  "user/get-children",
  async ({ filterData = null }, { dispatch, getState }) => {
    try {

      const searchParams = new URLSearchParams();

      if (filterData) {
        Object.keys(filterData).forEach(key => {
          if (filterData[key] !== undefined && filterData[key] !== null) {
            searchParams.append(key, filterData[key]);
          }
        });
      }

      dispatch(showDataLoader());
      const res = await api(
        "get",
        Constants.END_POINT.GET_CHILDREN + '?' + searchParams, {}, {},

        // {searchKeys:JSON.stringify(['firstName','lastName','gender']),searchString:search}

      );
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setAllChildDetails(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const fetchClubsAndSChools = createAsyncThunk(
  "user/get-clubs",
  async ({ filterData = null }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());
      const searchParams = new URLSearchParams();

      if (filterData) {
        Object.keys(filterData).forEach(key => {
          if (filterData[key] !== undefined && filterData[key] !== null) {
            searchParams.append(key, filterData[key]);
          }
        });
      }


      const res = await api(
        "get",
        Constants.END_POINT.GET_CLUBS_AND_SCHOOLS + '?' + searchParams,

        // {searchKeys:JSON.stringify(['firstName','lastName','gender']),searchString:search}

      );
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setClubsAndSchools(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const fetchClubs = createAsyncThunk(
  "user/get-clubs",
  async ({ }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());
      const res = await api(
        "get",
        Constants.END_POINT.GET_CLUBS,
      );
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setClubs(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const fetchSchools = createAsyncThunk(
  "user/get-clubs",
  async ({ }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());
      const res = await api(
        "get",
        Constants.END_POINT.GET_SCHOOLS,
      );
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setSchools(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const fetchClub = createAsyncThunk(
  "user/get-club",
  async ({ clubId }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());
      const res = await api(
        "get",
        Constants.END_POINT.GET_CLUB + '/' + clubId,

        // {searchKeys:JSON.stringify(['firstName','lastName','gender']),searchString:search}

      );
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setClubDetails(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const patchClubModalDdata = createAsyncThunk(
  "user/get-club-modal-data",
  async ({ data }, { dispatch, getState }) => {
    try {
      dispatch(parentSlice.actions.setAddClubModalData(data));
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const updateClubAction = createAsyncThunk(
  "auth/update-club",
  async ({ data, next }, { dispatch }) => {
    dispatch(showLoader());
    try {
      const res = await api("put", Constants.END_POINT.UPDATE_CLUB + data.id, data);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        dispatch(hideAddSchoolModal());
        dispatch(fetchClubsAndSChools({}));
        next();
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
      }
      return;
    } catch (error) {
      // Handle error if needed
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const updateChildProfile = createAsyncThunk(
  "user/update-child",
  async (
    { payload, setVisible = () => null, setDialog = () => null },
    { dispatch, getState }
  ) => {
    try {
      dispatch(showDataLoader());
      const res = await api(
        "put",
        Constants.END_POINT.UPDATE_CHILD_PROFILE + payload.id,
        payload
      );
      if (res.success && res.data) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(fetchAllChildren({}));
        setVisible(false);
        setDialog(false);
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const updatePasswordAction = createAsyncThunk(
  "user/update_password",
  async ({ data, setDialog, setData }, { dispatch, getState }) => {
    try {
      dispatch(showLoader());
      const payload = {
        oldPassword: data?.oldPassword,
        newPassword: data?.password,
      };

      const res = await api(
        "post",
        Constants.END_POINT.UPDATE_PASSWORD,
        payload
      );
      if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        setDialog({ visible: false });
        setData({
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        setDialog({ visible: false });
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      setDialog({ visible: false });
      return Promise.reject("An error occurred.");
    } finally {
      setDialog({ visible: false });
      dispatch(hideLoader());
    }
  }
);

export const AddClubEntityAction = createAsyncThunk(
  "auth/add-club",
  async ({ data, setVisible }, { dispatch }) => {
    dispatch(showLoader());
    try {
      const res = await api("post", Constants.END_POINT.ADD_CLUB_ENTITY, data);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        setVisible(false);
        dispatch(getEntitiesByClub({ clubId: data.club }));
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
      }
      return;
    } catch (error) {
      // Handle error if needed
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const getEntitiesByClub = createAsyncThunk(
  "user/get-clubs",
  async ({ clubId, filterData = null, setData }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());
      const searchParams = new URLSearchParams();

      if (filterData) {
        Object.keys(filterData).forEach(key => {
          if (filterData[key] !== undefined && filterData[key] !== null) {
            searchParams.append(key, filterData[key]);
          }
        });
      }


      const res = await api(
        "get",
        Constants.END_POINT.GET_CLUB_ENTITIES + clubId + '?' + searchParams,
      );
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(parentSlice.actions.setClubEntities(res.data));
        if (setData) {
          setData(res.data)
        }
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const getClassesBySchool = createAsyncThunk(
  "user/get-classes",
  async ({ clubId, setData }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());

      const res = await api(
        "get",
        Constants.END_POINT.GET_SCHOOL_CLASSES + clubId,
      );
      if (res.success && res.data) {
        setData(res.data)
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const getHousesBySchool = createAsyncThunk(
  "user/get-classes",
  async ({ clubId, setData }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());

      const res = await api(
        "get",
        Constants.END_POINT.GET_SCHOOL_HOUSES + clubId,
      );
      if (res.success && res.data) {
        setData(res.data)
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const getTeamsByClub = createAsyncThunk(
  "user/get-classes",
  async ({ clubId, setData }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());

      const res = await api(
        "get",
        Constants.END_POINT.GET_CLUB_TEAMS + clubId,
      );
      if (res.success && res.data) {
        setData(res.data)
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const getEntity = createAsyncThunk(
  "user/get-entity",
  async ({ entityId, setData }, { dispatch, getState }) => {
    try {

      dispatch(showDataLoader());
      const res = await api(
        "get",
        Constants.END_POINT.GET_CLUB_ENTITY + entityId,
      );
      if (res.success && res.data) {
        setData(prev => {
          return {
            ...prev,
            _id: res.data._id,
            name: res.data.name,
            entityType: res.data.entityType,
          };
        })
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const updateEntityAction = createAsyncThunk(
  "auth/update-entity",
  async ({ data, clubId, setVisible }, { dispatch }) => {
    dispatch(showLoader());
    try {
      const res = await api("post", Constants.END_POINT.UPDATE_CLUB_ENTITY, data);
      if (res.success) {
        if (data.isDeleted) {
          dispatch(
            showToast({ severity: "success", summary: "Entity deleted successfully.", detail: "" })
          );
        } else {
          dispatch(
            showToast({ severity: "success", summary: res.message, detail: "" })
          );
        }
        setVisible(false);
        dispatch(hideAddSchoolModal());
        dispatch(getEntitiesByClub({ clubId }));
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
      }
      return;
    } catch (error) {
      // Handle error if needed
    } finally {
      dispatch(hideLoader());
    }
  }
);

const parentSlice = createSlice({
  name: "children",
  initialState,
  reducers: {
    setAllChildDetails: (state, action) => {
      state.allChild = action.payload;
    },
    setChildDetail: (state, action) => {
      state.singleChild = action.payload;
    },
    setUniqueId: (state, action) => {
      state.uniqueId = action.payload;
    },
    setClubsAndSchools: (state, action) => {
      state.clubsAndSchools = action.payload;
    },
    setClubs: (state, action) => {
      state.clubs = action.payload;
    },
    setSchools: (state, action) => {
      state.schools = action.payload;
    },
    setClubDetails: (state, action) => {
      state.club = action.payload;
    },
    setAddClubModalData: (state, action) => {
      state.addClubModalData = action.payload;
    },
    setClubEntities: (state, action) => {
      state.clubEntities = action.payload;
    },
  },
});

export const {
  setUserDetail,
  setUniqueId,
  setChildDetail,
  setAllChildDetails,
  setClubDetails,
  setClubsAndSchools,
  setClubs,
  setSchools,
  setAddClubModalData,
} = parentSlice.actions;

export default parentSlice.reducer;
