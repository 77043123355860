import React from "react";
import PrimaryButton from "../buttons/button";
import { Dialog } from "primereact/dialog";

const QRCard = ({ qrVisible, setQRVisible, qrImg, DownloadQRCode }) => {
  return (
    <Dialog
      visible={qrVisible}
      modal
      style={{ width: "23rem" }}
      header={"QR CODE"}
      className=" p-0 border-round-xl"
      onHide={() => setQRVisible(false)}
      content={({ hide }) => (
        <div className=" border-round-xl bg-white p-3">
          <div className="flex justify-content-end">
            <span onClick={() => setQRVisible(false)}>
              <i className="pi pi-times font-semibold cursor-pointer"></i>
            </span>
          </div>
          <div className="flex flex-column align-items-center justify-content-center w-full">
            <div id="print" className="">
              <h2 className="kagitingan text-center">{qrImg?.firstName}</h2>{" "}
              <div>
                <img src={qrImg?.qrCode} alt="" />
              </div>{" "}
            </div>
            <PrimaryButton
              onClick={() => DownloadQRCode()}
              label={"Download"}
            ></PrimaryButton>
          </div>
        </div>
      )}
    ></Dialog>
  );
};

export default QRCard;
