import React from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { Dialog } from "primereact/dialog";

const QRScanner = ({
  visible,
  onHideScanner = () => {},
  width = "30vw",
  onScan,
}) => {
  const handleScan = (e) => {
    if (e && e.length) {
      const obj = e[0];
      onScan(JSON.parse(obj.rawValue));
    }
  };

  return (
    <Dialog
      className="scanner"
      visible={visible}
      style={{ width }}
      breakpoints={{ "960px": "50vw", "641px": "95vw" }}
      onHide={onHideScanner}
      content={() => (
        <div className="scanner">
          <i
            className="pi pi-times cursor-pointer scanner-close-button"
            onClick={onHideScanner}
          />
          <Scanner onScan={handleScan} allowMultiple={false} />
        </div>
      )}
    />
  );
};

export default QRScanner;
