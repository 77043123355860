import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

export const TableColumns = () => {
  const navigate = useNavigate();
  const ParentColumns = [
    {
      field: "radRunIdentifier",
      header: "RAD RUN #",
    },
    {
      field: "firstName",
      header: "Full Name",
      body: (e) => (
        <>
          <span className="pink cursor-pointer ">
            {e?.firstName}&nbsp;
            {e?.lastName}
          </span>
        </>
      ),
      sortable: true,
    },
    {
      field: "gender",
      header: "Gender",
      body: "GENDER",
      sortable: true,
    },
    {
      field: "dob",
      header: "Date of Birth",
      body: "DATEOFBIRTH",
      sortable: true,
    },
    {
      field: "school",
      header: "School",
      body: (e) => (
        <>
          <span>{e?.school?.name}</span>
        </>
      ),
    },
    {
      field: "club",
      header: "Club",
      body: (e) => (
        <>
          <span>{e?.club?.name}</span>
        </>
      ),
    },

    { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
  ];
  // const TeacherColumns = [
  //   {
  //     field: "radRunIdentifier",
  //     header: "RAD RUN #",
  //   },
  //   {
  //     field: "firstName",
  //     header: "Full Name",
  //     body: (e) => (
  //       <>
  //         <span className="pink cursor-pointer ">
  //           {e.firstName}&nbsp;
  //           {e.lastName}
  //         </span>
  //       </>
  //     ),
  //     sortable: true,
  //   },
  //   {
  //     field: "gender",
  //     header: "Gender",
  //     body: "GENDER",
  //   },
  //   {
  //     field: "dob",
  //     header: "Date of Birth",
  //     body: "DATEOFBIRTH",
  //     sortable: true,
  //   },
  //   {
  //     field: "school",
  //     header: "School",
  //     sortable: true,
  //   },
  //   { field: "class", header: "Class", sortable: true },
  //   { field: "house", header: "House", sortable: true },
  //   { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
  // ];

  // const EventOrgnizerColumns = [
  //   {
  //     field: "name",
  //     header: "Event Name",
  //     body: (e) => (
  //       <>
  //         <Link className="no-underline" to={`/event_info/` + e._id}>
  //           <span className="pink cursor-pointer ">{e.name}</span>
  //         </Link>
  //       </>
  //     ),
  //   },
  //   {
  //     field: "address",
  //     header: "Address",
  //     body: (e) => e?.address.name,
  //     sortable: true,
  //   },
  //   {
  //     field: "date",
  //     header: "Date & Time",
  //     body: (e) =>
  //       `${moment(e?.date).format("DD-MM-YYYY")} ${moment(e?.time).format(
  //         "HH:mm"
  //       )}`,
  //     sortable: true,
  //   },
  //   {
  //     field: "status",
  //     header: "Status",
  //     body: "STATUSTEMPLATE",
  //     sortable: true,
  //   },
  // ];

  const clubColumns = [
    {
      field: "name",
      header: "Name",
      sortable: true,
    },
    {
      field: "contactEmail",
      header: "Contact Email",
    },
    {
      field: "position",
      header: "Position",
    },
    {
      field: "website",
      header: "Website",
    },
    {
      field: "address",
      header: "Address",
    },
    {
      field: "state?.name",
      header: "State",
    },
    {
      field: "country?.name",
      header: "Country",
    },
    { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
  ];

  const clubEntntiyColumns = [
    {
      field: "name",
      header: "Name",
      sortable: true,
    },
    {
      field: "entityType",
      header: "Type",
    },
    { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
  ];

  return {
    ParentColumns,
    clubColumns,
    clubEntntiyColumns,
  };
};
